import { parseDateTime } from 'common/utils/date';
import { DateTimepicker } from 'components/DateTimepicker';
import { fieldToRules } from 'pages/ContentEditorPage/ContentEditor/helpers';
import React from 'react';
import { Controller } from 'react-hook-form';

import { useContentEditorProvider } from '../../useContentEditorProvider';
import { Props } from './props';

export const ContentDateTimeInput: React.FC<Props> = ({ field }) => {
  const { form, embedLevel, isReviewing, handleFieldChange } = useContentEditorProvider();

  const { control, setValue, getValues } = form;

  const inputValue = parseDateTime(getValues(field.slug));

  return (
    <Controller
      name={field.slug}
      as={DateTimepicker}
      id={`${field.slug}-${embedLevel}`}
      control={control}
      onChangeDateTime={(datetime: string) => {
        setValue(field.slug, datetime);
        handleFieldChange(field.slug);
      }}
      dateFormat="dd/MM/yyyy"
      placeholderText="DD/MM/YYYY"
      datetime={inputValue}
      disabled={field.disabled || isReviewing}
      rules={fieldToRules(field)}
    />
  );
};
