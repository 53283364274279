import { formatTime, parseTime } from 'common/utils/date';
import { Timepicker } from 'components/Timepicker';
import { fieldToRules } from 'pages/ContentEditorPage/ContentEditor/helpers';
import React, { useMemo } from 'react';
import { Controller } from 'react-hook-form';

import { useContentEditorProvider } from '../../useContentEditorProvider';
import { Props } from './props';

export const ContentTimeInput: React.FC<Props> = ({ field }) => {
  const { form, embedLevel, isReviewing, handleFieldChange } = useContentEditorProvider();

  const { control, setValue, getValues } = form;

  const val = getValues(field.slug);

  const inputValue = useMemo(() => {
    return parseTime(val);
  }, [val]);

  return (
    <Controller
      name={field.slug}
      as={Timepicker}
      id={`${field.slug}-${embedLevel}`}
      control={control}
      disabled={field.disabled || isReviewing}
      onChangeTime={(date: Date) => {
        setValue(field.slug, formatTime(date));
        handleFieldChange(field.slug);
      }}
      withIcon
      placeholderText="HH:MM"
      time={inputValue}
      rules={fieldToRules(field)}
    />
  );
};
