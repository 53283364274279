import { ColourInput } from 'components/ColourInput/component';
import { fieldToRules } from 'pages/ContentEditorPage/ContentEditor/helpers';
import React from 'react';
import { Controller } from 'react-hook-form';

import { useContentEditorProvider } from '../../useContentEditorProvider';
import { Props } from './props';

export const ContentColourInput: React.FC<Props> = ({ field }) => {
  const { form, isReviewing, handleFieldChange } = useContentEditorProvider();

  const { control, setValue, getValues } = form;

  const inputValue = getValues(field.slug);

  const colorValue = inputValue?.toString()?.toUpperCase() || '#0000FF';

  return (
    <Controller
      name={field.slug}
      as={ColourInput}
      slug={field.slug}
      disabled={field.disabled || isReviewing}
      control={control}
      color={colorValue}
      handleColorChange={(color: string) => {
        setValue(field.slug, color.toUpperCase());
        handleFieldChange(field.slug);
      }}
      rules={fieldToRules(field)}
    />
  );
};
